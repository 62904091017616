<template>
  <section class="userManagement">
    <prodige-title>{{
      $t('userManagement.title.userManagement')
    }}</prodige-title>
    <span ref="focusStart" tabindex="0" class="focus-start" />
    <form autocomplete="off" @submit.prevent="submitForm">
      <prodige-detail-head>
        <div class="relative w-1/4 input mr-8">
          <ab-input
            v-model="userData.last_name"
            :label="$t('label.name')"
            :error="$v.userData.last_name.$error"
            type="text"
            required
          />
          <span v-if="$v.userData.last_name.$error" class="err-msg">{{
            $t('userManagement.error.lastName')
          }}</span>
        </div>
        <div class="relative w-1/4 input mr-8">
          <ab-input
            v-model="userData.first_name"
            :label="$t('label.firstName')"
            :error="$v.userData.first_name.$error"
            type="text"
            required
          />
          <span v-if="$v.userData.first_name.$error" class="err-msg">{{
            $t('userManagement.error.firstName')
          }}</span>
        </div>
        <div class="relative w-1/4 mr-8">
          <multiple-select
            v-model="userData.Company"
            :select-label="$t('label.company')"
            :placeholder="$t('label.companyPlaceholder')"
            :allow-empty="false"
            :multiple="true"
            :options="availableCompanies"
            :error="$v.userData.Company.$error"
            :limit="1"
            :limit-text="limitText"
            label="label"
            track-by="id"
            class="mandatory"
            @input="isDirty = true"
          />
          <span v-if="$v.userData.Company.$error" class="err-msg">{{
            $t('userManagement.error.company')
          }}</span>
        </div>
        <div class="relative w-1/4">
          <multiple-select
            v-model="userData.Status"
            :select-label="$t('label.status')"
            :placeholder="$t('label.statusPlaceholder')"
            :allow-empty="false"
            :multiple="false"
            :options="status"
            :error="$v.userData.Status.$error"
            label="label"
            track-by="id"
            class="mandatory"
            @input="isDirty = true"
          />
          <span v-if="$v.userData.Status.$error" class="err-msg">{{
            $t('userManagement.error.status')
          }}</span>
        </div>
      </prodige-detail-head>

      <prodige-card>
        <!-- USER SECTION -->
        <div class="flex w-full">
          <span class="label w-4/12 block text-md text-dark-grey uppercase">{{
            $t('label.profile')
          }}</span>
          <div class="w-10/12 flex flex-wrap justify-between">
            <div class="w-47 relative">
              <ab-input
                v-model="userData.email"
                :label="$t('label.email')"
                :error="$v.userData.email.$error"
                name="email"
                required
              />
              <span v-if="$v.userData.email.$error" class="err-msg">{{
                $t('userManagement.error.email')
              }}</span>
            </div>

            <div class="w-47 relative">
              <ab-input
                v-model="userData.phone_number"
                v-mask="'## ## ## ## ##'"
                :label="$t('label.phone')"
                name="phone"
                :error="$v.userData.phone_number.$error"
              />
              <span v-if="$v.userData.phone_number.$error" class="err-msg">{{
                $t('userManagement.error.phone')
              }}</span>
              <span
                v-if="!$v.userData.phone_number.phoneVerification"
                class="err-msg"
                >{{ $t('error.dataTypeNumber') }}</span
              >
            </div>

            <div class="w-47 relative mt-8">
              <multiple-select
                v-model="userData.Profile"
                :select-label="$t('label.profile')"
                :placeholder="$t('label.profilePlaceholder')"
                :options="availableProfiles"
                :error="$v.userData.Profile.$error"
                :allow-empty="false"
                track-by="id"
                label="label"
                class="mandatory"
              />
              <span v-if="$v.userData.Profile.$error" class="err-msg">{{
                $t('userManagement.error.profile')
              }}</span>
            </div>

            <div class="w-47 relative mt-8">
              <multiple-select
                v-model="userData.Role"
                :select-label="$t('label.quality')"
                :placeholder="$t('label.qualityPlaceholder')"
                :options="roles"
                track-by="id"
                label="label"
                :multiple="false"
                @input="isDirty = true"
              />
            </div>
            <!-- Avatar section -->
            <div class="w-full relative mt-8">
              <prodige-file-input
                ref="fileinput"
                :files="userData.Avatar"
                :label="pondLabel"
                :multiple="false"
                :max-file-size="maxFileSizeTrunc"
                :accepted-file-types="pondAcceptedFileType"
                @addfile="addImage, (isDirty = true)"
                @removefile="removeImage, (isDirty = true)"
              />
            </div>
          </div>
        </div>

        <!-- GROUPS SECTION -->
        <div v-if="isProfileSelected && !isProclub" class="flex w-full mt-12">
          <div class="w-4/12">
            <span class="label block text-md text-dark-grey uppercase">{{
              $t('userManagement.text.perimetre')
            }}</span>
            <p class="pb-8 italic text-xs w-1/2 mt-4">
              {{ $t(perimeterDescription) }}
            </p>
          </div>
          <div class="w-10/12">
            <!-- Adherent perimeter -->
            <!-- Adherent groups -->
            <div
              v-if="!isSupplier && availableAdherentGroups.length"
              class="w-full relative"
            >
              <prodige-draggable
                v-model="local_groupAdherents"
                :source="availableAdherentGroups"
                label-key="name"
                search-key="name"
                :auto-fill-source="false"
                :source-label="
                  $t('userManagement.text.adherentGroupNotIncluded')
                "
                :destination-label="
                  $t('userManagement.text.adherentGroupIncluded')
                "
              />
            </div>

            <!-- Adherents -->
            <div v-if="!isSupplier" class="w-full relative mt-6">
              <prodige-draggable
                v-model="local_adherents"
                :source="availableAdherents"
                label-key="name"
                search-key="name"
                :auto-fill-source="false"
                :source-label="$t('userManagement.text.adherentNotIncluded')"
                :destination-label="$t('userManagement.text.adherentIncluded')"
              />
            </div>

            <!-- Families -->
            <div v-if="!isSupplier" class="mt-6">
              <prodige-multiple-draggable
                v-if="availableFamilies"
                v-model="local_families"
                :select-label="$t('label.family')"
                :select-placeholder="$t('label.familyPlaceholder')"
                button-label="Ajouter la famille"
                :select-options="availableFamilies"
                :auto-fill="$route.meta.isCreate === true ? true : false"
                select-label-key="label"
                select-value-key="id"
                sub-entity="Subfamily"
                sub-entity-label="label"
                sub-entity-value="id"
                source-label="Sous-familles non incluses"
                destination-label="Sous-familles incluses"
              />
            </div>

            <!-- TODO: Validator lvl 1, lvl 2 -->

            <!-- Supplier perimeter -->
            <div v-if="isSupplier" class="w-47 mt-6">
              <multiple-select
                v-if="suppliers.length"
                v-model="userData.Supplier"
                :select-label="$t('label.supplier')"
                :placeholder="$t('label.supplierPlaceholder')"
                :options="suppliers"
                track-by="id"
                label="name"
                :multiple="false"
              />
            </div>
          </div>
        </div>

        <!-- Validator -->
        <div
          v-if="isProfileSelected && !isProclub && !isSupplier"
          class="flex w-full mt-12"
        >
          <span class="label w-4/12 block text-md text-dark-grey uppercase">{{
            $t('profile.orderValidators')
          }}</span>
          <div class="w-10/12 flex flex-wrap justify-between">
            <div class="w-47 relative mb-8">
              <multiple-select
                v-model="validatorLv1"
                :select-label="$t('label.validatorLv1')"
                :placeholder="$t('label.validatorPlaceholder')"
                :allow-empty="true"
                :multiple="false"
                :options="availabledValidatorLv1"
                :error="false"
                :custom-label="
                  customlabel((item) => `${item.first_name} ${item.last_name}`)
                "
                track-by="id"
              />
            </div>
            <div class="w-47 relative">
              <multiple-select
                v-model="validatorLv2"
                :disabled="!validatorLv1"
                :select-label="$t('label.validatorLv2')"
                :placeholder="$t('label.validatorPlaceholder')"
                :allow-empty="true"
                :multiple="false"
                :options="availabledValidatorLv2"
                :error="false"
                :custom-label="
                  customlabel((item) => `${item.first_name} ${item.last_name}`)
                "
                track-by="id"
              />
            </div>
            <div class="w-47 relative">
              <multiple-select
                v-model="replacementValidator"
                :select-label="$t('label.replacementValidator')"
                :placeholder="$t('label.validatorPlaceholder')"
                :allow-empty="true"
                :multiple="false"
                :options="validators"
                :error="false"
                :custom-label="
                  customlabel((item) => `${item.first_name} ${item.last_name}`)
                "
                track-by="id"
              />
            </div>
          </div>
        </div>

        <!-- FOOTER SECTION -->
        <section class="flex w-full mt-12">
          <div class="w-4/12 block">
            <prodige-history
              v-if="!$route.meta.isCreate"
              :created-at="userData.createdAt"
              :updated-at="userData.updatedAt"
            />
          </div>
          <div class="w-10/12 flex flex-no-wrap justify-between">
            <div>
              <prodige-button
                v-if="!$route.meta.isCreate && false"
                class="btn secondary"
                disabled
                @click.prevent="fakeUserService"
              >
                {{ $t('userManagement.action.fakeUser') }}
              </prodige-button>
            </div>
            <div>
              <prodige-button
                v-if="!$route.meta.isCreate"
                type="button"
                class="btn primary ml-4"
                :disabled="pending"
                @click.native="cancelForm"
              >
                {{ $t('action.cancel') }}
              </prodige-button>
              <prodige-button class="btn secondary ml-4" :disabled="pending">
                {{ $t('action.save') }}
              </prodige-button>
            </div>
          </div>
        </section>
      </prodige-card>
    </form>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import greenlet from 'greenlet'
import {
  AbInput,
  AbCheckbox,
  AbDatepicker,
  MultipleSelect,
} from '@ab/material-components'
const {
  required,
  minLength,
  maxLength,
  requiredIf,
  email,
  numeric,
  boolean,
} = require('vuelidate/lib/validators')
const { phoneVerification } = '../mixins'

const {
  ProdigeCard,
  ProdigeButton,
  ProdigeFileInput,
  ProdigeDraggable,
  ProdigeHistory,
  ProdigeMultipleDraggable,
} = '../'

const fnGetGroups = greenlet((groups) => groups.map((group) => group.id))
const fnGetServices = greenlet((services) =>
  services.map((service) => service.id)
)
const fnGetCatalogs = greenlet((availableAdherents) => {
  const cataloguesSet = new Set()

  availableAdherents.forEach((adherent) => {
    const { AdherentsCatalogs } = adherent
    const catalogIds = AdherentsCatalogs.map(
      (assocociation) => assocociation.Catalog && assocociation.Catalog.id
    ).filter((item) => item)

    // @see https://stackoverflow.com/questions/50881453/how-to-add-an-array-of-values-to-a-set
    catalogIds.forEach(cataloguesSet.add, cataloguesSet)
  })
  return cataloguesSet
})

export default {
  name: 'UserManagementEdit',
  components: {
    AbInput,
    MultipleSelect,
    ProdigeCard,
    ProdigeButton,
    ProdigeFileInput,
    ProdigeDraggable,
    ProdigeHistory,
    ProdigeMultipleDraggable,
  },
  data() {
    return {
      userData: {},
      image: '',
      maxFileSize: 10000000,
      maxFileSizeTrunc: '10 MB',
      pondLabel: 'Glissez votre fichier image ou <u>cliquez ici</u>',
      pondAcceptedFileType: ['image/png', 'image/jpeg', 'image/gif'],
      local_groupAdherents: [],
      local_adherents: [],
      pending: false,
      local_adherents_state: [],
      store_families: [],
      local_families: [],
      selected_suppliers: [],
      fnGetGroups,
      fnGetServices,
      fnGetCatalogs,
      validatorLv1: null,
      validatorLv2: null,
      replacementValidator: null,
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.userManagement.user,
      profiles: (state) => state.profile.profiles,
      availableProfiles() {
        // If the current user is an adherent, the only available profile is adherent
        // So we filter out VALUE and SUPPLIER profiles
        if (this.isCurrentUserAdherent) {
          return this.profiles.filter((profile) => {
            return (
              profile.Profile_type.machine_name !==
                this.constants.profilType.VALAE &&
              profile.Profile_type.machine_name !==
                this.constants.profilType.SUPPLIER
            )
          })
        }
        return this.profiles
      },
      companies: (state) => state.company.companies,
      availableCompanies() {
        const { user } = this.$store.getters
        const currentUserCompanies = user.Company
          ? user.Company.map((company) => company.id)
          : []
        return this.companies.filter((company) =>
          currentUserCompanies.includes(company.id)
        )
      },
      status: (state) => state.status.allStatus,
      userPerimeter: (state) => state.userManagement.userPerimeter,
      adherents: (state) => state.adherent.adherents,
      adherentsByGroups: (state) => state.adherent.adherentsByGroups,
      availableAdherents() {
        if (this.isCurrentUserValae) {
          return this.adherentsByGroups
        }
        return this.userPerimeter.adherents ? this.userPerimeter.adherents : []
      },
      groupAdherents: (state) => state.groupAdherent.groupAdherents,
      groupAdherentsByCompany: (state) =>
        state.groupAdherent.groupAdherentsByCompany,
      roles: (state) => state.setting.roles,
      familiesByAdherent: (state) => state.family.familiesByAdherent,
      availableFamilies() {
        if (this.isCurrentUserValae) {
          return this.familiesByAdherent
        }
        return this.userPerimeter.families ? this.userPerimeter.families : []
      },
      suppliers: (state) => state.supplier.suppliers,
      availableAdherentGroups() {
        const groups = this.userPerimeter.adherentGroups
          ? this.userPerimeter.adherentGroups
          : []
        const groupList = this.isCurrentUserValae
          ? this.groupAdherentsByCompany
          : groups

        // FIXME: Why creating a new object ?
        return groupList.map((a) =>
          Object.assign({}, { id: a.id, name: a.name })
        )
      },
      validators: (state) => state.userManagement.validators,
    }),
    isProclub() {
      if (
        this.userData.Profile !== undefined &&
        this.userData.Profile.Profile_type.machine_name === 'valae'
      ) {
        return true
      }
      return false
    },
    isSupplier() {
      if (
        this.userData.Profile !== undefined &&
        this.userData.Profile.Profile_type.machine_name === 'supplier'
      ) {
        return true
      }
      return false
    },
    isProfileSelected() {
      return this.userData && this.userData.Profile && this.userData.Profile.id
    },
    isCurrentUserValae() {
      const { user } = this.$store.getters
      if (
        user.Profile &&
        user.Profile.Profile_type &&
        user.Profile.Profile_type.machine_name ===
          this.constants.profilType.VALAE
      ) {
        return true
      }
      return false
    },
    isCurrentUserAdherent() {
      const { user } = this.$store.getters
      if (
        user.Profile &&
        user.Profile.Profile_type &&
        user.Profile.Profile_type.machine_name ===
          this.constants.profilType.ADHERENT
      ) {
        return true
      }
      return false
    },
    isCurrentUserSupplier() {
      const { user } = this.$store.getters
      if (
        user.Profile &&
        user.Profile.Profile_type &&
        user.Profile.Profile_type.machine_name ===
          this.constants.profilType.SUPPLIER
      ) {
        return true
      }
      return false
    },
    perimeterDescription() {
      return !this.isSupplier
        ? 'userManagement.description.perimetreAdherent'
        : 'userManagement.description.perimetreSupplier'
    },
    availabledValidatorLv1() {
      if (this.validatorLv2) {
        return this.validators.filter(
          (validator) => validator.id !== this.validatorLv2.id
        )
      }
      return this.validators
    },
    availabledValidatorLv2() {
      if (this.validatorLv1) {
        return this.validators.filter(
          (validator) => validator.id !== this.validatorLv1.id
        )
      }
      return this.validators
    },
  },
  watch: {
    user() {
      this.userData = this.deepCopy(this.user)
      this.local_groupAdherents = this.deepCopy(this.userData.GroupAdherent)
      this.local_adherents = this.deepCopy(this.userData.Adherent)
      if (this.user && this.user.Family) {
        this.local_families = this.deepCopy(this.user.Family)
      }
      this.validatorLv1 = this.userData.ValidatorOne
      this.validatorLv2 = this.userData.ValidatorTwo
      this.replacementValidator = this.userData.ReplacementValidator
    },
    adherents() {
      this.local_adherents_state = this.adherents.map((a) =>
        Object.assign({}, { id: a.id, name: a.name })
      )
    },
    async isProfileSelected() {
      // Load info need for adherent
      this.showSpinner()
      // We have different behavior regarding the current user profile
      // And the selected profile of the currently edited user

      // Adherent profile
      if (!this.isSupplier && !this.isProclub) {
        if (this.isCurrentUserValae) {
          // If the current logged user has the "valae" profile
          //  => We only load adherent groups of the same "service" (Public/Private or both)
          // If the current logged user is an adherent
          //  => We load adherent groups that matches his perimeter (entities he has access to)
          const { user } = this.$store.getters

          const serviceIds = user.Company.map((service) => service.id)
          await this.$store.dispatch('getGroupAdherentsByCompany', serviceIds)

          const groupIds = await this.fnGetGroups(this.availableAdherentGroups)
          // const groupIds = this.availableAdherentGroups.map(group => group.id);

          const services = await this.fnGetServices(user.Company)
          // const services = user.Company.map(service => service.id);

          await this.$store.dispatch('getAdherentsByGroups', {
            groupIds,
            services,
          })

          // In order to recover only the families that are associated with the available adherent
          // We need to create a new Set of Catalogues IDs
          //
          // To do so, we iterate over all the available adherents to recover there catalog ID
          // From these ID we will recover families and sub families
          const cataloguesSet = await this.fnGetCatalogs(
            this.availableAdherents
          )
          const catalogToRecover = Array.from(cataloguesSet)

          await this.$store.dispatch('getFamiliesByAdherent', catalogToRecover)
        } else if (this.isCurrentUserAdherent) {
          await this.$store.dispatch('getUserPerimeter')
        }
      } else {
        const { user } = this.$store.getters

        const serviceIds = user.Company.map((service) => service.id)
        await this.$store.dispatch('getCompanySuppliers', serviceIds)
      }
      this.hideSpinner()
    },
    'local_groupAdherents.length': function watchAdherentGroups() {
      if (this.availableAdherents.length) {
        this.local_groupAdherents.forEach((group) => {
          const { id } = group

          // Filter available adherents to return those belonging to the current selected group
          this.availableAdherents
            .filter((adherent) => {
              return adherent.GroupAdherent && adherent.GroupAdherent.id === id
            })
            // Check if the adherent has not yet been selected
            .forEach((adherent) => {
              const currentAdherents = this.local_adherents.map(
                (adherent) => adherent.id
              )
              if (!currentAdherents.includes(adherent.id)) {
                this.local_adherents.push(adherent)
              }
            })
        })
      }
    },
    local_adherents() {
      this.$store.dispatch('getValidatorUsers', {
        adherents: this.local_adherents.map((adherent) => adherent.id),
        userId: this.userData.id,
      })
    },
  },
  mounted() {
    this.init()
  },
  validations: {
    userData: {
      first_name: {
        required,
        maxLength: maxLength(45),
        minLength: minLength(1),
      },
      last_name: {
        required,
        minLength: minLength(1),
        maxLength: maxLength(45),
      },
      email: {
        required,
        email,
        maxLength: maxLength(45),
      },
      phone_number: { maxLength: maxLength(20), phoneVerification },
      Company: {
        required,
      },
      Status: {
        required,
      },
      Profile: {
        required,
      },
    },
  },
  methods: {
    async init() {
      await this.$store.dispatch('getProfiles')
      await this.$store.dispatch('getAllStatus')
      await this.$store.dispatch('getCompanies')
      await this.$store.dispatch('getRoles')
      if (!this.$route.meta.isCreate && this.$route.params.id) {
        const data = await this.$store.dispatch(
          'getUser',
          this.$route.params.id
        )
        if (data === false) {
          this.$store.dispatch('addMessage', {
            key: 'UserManagementErrorLoadUser',
            params: { display: 'userManagement.error.loadUser', error: true },
          })
          this.$router.go(-1)
        }
      }
      this.hideSpinner()
    },
    async cancelForm() {
      this.$router.push({ name: 'UserList' })
    },
    async submitForm() {
      this.startValidation()
      this.startForm()

      // Handle error
      if (this.$v.userData.$error) {
        this.$store.dispatch('addMessage', {
          key: 'submitFormUserManagementError',
          params: {
            display: 'userManagement.error.formVerificationError',
            error: true,
          },
        })
        this.errorForm()
        return
      }
      // Handle creation case
      if (this.$route.meta.isCreate) {
        // First Upload the user avatar if there is one
        if (this.image) {
          const [avatar] = await this.$store.dispatch('uploadFile', {
            files: [this.image],
            path: '/user',
          })
          this.userData.avatar_file_id = avatar.id
        }

        // Create the user entity
        const data = await this.$store.dispatch('createUser', {
          ...this.userData,
          ...(this.userData.Role !== undefined
            ? { role_id: this.userData.Role.id }
            : {}),
          status_id: this.userData.Status.id,
          profile_id: this.userData.Profile.id,
          GroupAdherent: this.local_groupAdherents.map((item) => item.id),
          Adherent: this.local_adherents.map((item) => item.id),
          Family: this.local_families.map((item) => ({
            id: item.id,
            values: item.values,
          })),
          ValidatorOne: this.validatorLv1 ? this.validatorLv1 : null,
          ValidatorTwo: this.validatorLv2 ? this.validatorLv2 : null,
          ReplacementValidator: this.replacementValidator
            ? this.replacementValidator
            : null,
        })
        if (data) {
          this.$store.dispatch('addMessage', {
            key: 'submitFormUserManagementSuccess',
            params: {
              display: 'userManagement.success.submitForm',
              error: false,
            },
          })
          this.$router.push(
            { name: 'UserEdit', params: { id: data.id } },
            this.init
          )
        }
      } else {
        if (this.image) {
          const [avatar] = await this.$store.dispatch('uploadFile', {
            files: [this.image],
            path: '/user',
          })
          this.userData.avatar_file_id = avatar.id
        }
        const data = await this.$store.dispatch('updateUser', {
          ...this.userData,
          ...(this.userData.Role !== null
            ? { role_id: this.userData.Role.id }
            : {}),
          ...this.userData.Company,
          status_id: this.userData.Status.id,
          profile_id: this.userData.Profile.id,
          GroupAdherent: [...this.local_groupAdherents],
          Adherent: [...this.local_adherents],
          Family: [...this.local_families],
          ValidatorOne: this.validatorLv1 ? this.validatorLv1 : null,
          ValidatorTwo: this.validatorLv2 ? this.validatorLv2 : null,
          ReplacementValidator: this.replacementValidator
            ? this.replacementValidator
            : null,
        })
        if (data) {
          this.$store.dispatch('addMessage', {
            key: 'submitFormUserManagementSuccess',
            params: {
              display: 'userManagement.success.submitForm',
              error: false,
            },
          })
        }
      }
      this.endForm()
    },
    addImage(file) {
      this.image = file
    },
    removeImage() {
      this.image = {}
    },
    fakeUserService() {},
  },
}
</script>
